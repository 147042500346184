// export const hostWsLogin    = 'ws://web.wee.vn:81/ws/login'
//Local
// export const hostWsNewPOS   = 'ws://weezi.biz:7088/ws/webConnection'

//dev
// export const hostWsNewPOS   = 'wss://mpos-dev.facepay.vn/ws/webConnection'
// export const hostWsLoginStore = 'wss://mpos-dev.facepay.vn/ws/mpos/login?token='
// export const hostWsNewPOS   = 'wss://dev.facepay.vn/ws/webConnection'
// export const hostWsLoginStore = 'wss://dev.facepay.vn/ws/mpos/login?token='

//CMC
export const hostWsNewPOS   = 'wss://pos.facepay.vn/ws/webConnection'
export const hostWsLoginStore = 'wss://pos.facepay.vn/ws/mpos/login?token='

// export const hostWsLogin    = 'ws://pos.facepay.vn/ws/login'
// export const hostWsNewPOS   = 'ws://pos.facepay.vn/ws/webConnection'