import React, { useState, useEffect, useRef, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//image
import logo_facepay from "../../images/logo_facepay_blue.svg";
// import avatar_demo from "../../images/avatar_demo.svg"


//styles
import { Bound } from "./index.styles"

// data
import menuData, { keyMenu } from "../../data/menuData";

// constant
import { CHAIN_MANAGER, FACEPAY_USER_DATA, STORE_MANAGER, WUM } from "../../constant/key";

// component
import IcLocation from '../../images/component/IcLocation_blue';
import IcPhoneMerchant from '../../images/component/IcPhoneMerchant_blue'
import IcLogoutRed from '../../images/component/IcLogoutRed'
// import LogoutControl from '../Control/LogoutControl';
// import ModalControl from '../Control/ModalControl';
// import ToggleContentControl from '../Control/ToggleContentControl';

// action
import { logout } from '../../redux/actions/userAction'
import { getShop } from '../../redux/actions/shopAction';
import { getFilterPOS } from '../../redux/actions/posActions';

const authStatusTypes = {
    NOT_AUTH: "NOT_AUTH",
    LOGED_IN: "LOGED_IN",
    ADDED_DEVICE: "ADDED_DEVICE"
}

const Header = () => {

    // const userDataLocal = JSON.parse(localStorage.getItem(FACEPAY_USER_DATA));

    const dispatch = useDispatch()

    const { userReducer, shopReducer, pagingReducer, posReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        shopReducer: state.shopReducer,
        pagingReducer: state.pagingReducer,
        posReducer: state.posReducer,
    }))

    const [authStatus, setAuthStatus] = useState(authStatusTypes.NOT_AUTH);
    const [userDataLocal, setUserDataLocal] = useState({})
    // const [stylesForLogoutPopup, setStylesForLogoutPopup] = useState('');
    const history = useHistory();
    const selfRef = useRef(null);
    const logoRef = useRef(null);
    // const toogleBtnRef = useRef(null);

    // useEffect(() => {

    //     const _handleScroll = () => {
    //         const self = selfRef.current;
    //         const sticky = self.offsetTop;
    //         const windowYOffSet = window.pageYOffset;

    //         if (windowYOffSet > sticky && windowYOffSet > 20) {
    //             self.classList.add("sticky");
    //         } else {
    //             self.classList.remove("sticky");
    //         }
    //     }

    //     window.addEventListener('scroll', _handleScroll)

    //     return () => {
    //         window.removeEventListener('scroll', _handleScroll)
    //     }
    // }, [])

    useEffect(() => {
        const { loginResult } = userReducer;
        if (!loginResult) {
            setAuthStatus(authStatusTypes.NOT_AUTH);
            return;
        }
        const { LinkPos, Role } = loginResult;
        if ([CHAIN_MANAGER].includes(Role) || LinkPos) {
            setAuthStatus(authStatusTypes.ADDED_DEVICE);
            return;
        }
        setAuthStatus(authStatusTypes.LOGED_IN);
    }, [userReducer])

    useEffect(() => {
        if (!userReducer.loginResult) return;
        setUserDataLocal(userReducer.loginResult)
    }, [userReducer.loginResult])

    // useEffect(() => {
    //     if (authStatus !== authStatusTypes.ADDED_DEVICE) {
    //         logoRef.current.style.pointerEvents = "none";
    //         return;
    //     }
    //     logoRef.current.style.pointerEvents = "auto";
    // }, [authStatus, logoRef])

    const _handleClickLogo = () => {
        const pushs = {
            [authStatusTypes.NOT_AUTH]: () => history.push("/login"),
            [authStatusTypes.LOGED_IN]: () => history.push("/main/pos/add"),
            [authStatusTypes.ADDED_DEVICE]: () => history.push("/main/statistic")
        }
        const go = pushs[authStatus];

        go();
    }

    const menuDatas = useMemo(() => menuData.map(item => {
        switch(item.key) {
            case keyMenu.STATISTIC:
                return {...item, callFunc: () => dispatch(getShop())} 
            case keyMenu.POS_DEVICE:
                return {...item, callFunc: () => {
                    let listShopID = [];
                    if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
                        listShopID = [shopReducer.data.Shops[0].ID];
                    } else if (userDataLocal.Role === CHAIN_MANAGER) {
                        listShopID = posReducer.shopFilter.listShopID
                        ? posReducer.shopFilter.listShopID
                        : shopReducer.data.Shops.map((item) => item.ID);
                    }
                    dispatch(getFilterPOS(
                        pagingReducer.pos_list.pageSize,
                        pagingReducer.pos_list.pageNumber,
                        listShopID,
                        history 
                    ))
                }}
            default:
                return;
        }
    }), [dispatch, userDataLocal, shopReducer, posReducer, pagingReducer, history])

    const _renderMenu = () => {
        if (authStatus !== authStatusTypes.ADDED_DEVICE) {
            return null;
        }
        if (userReducer.isBlocked) return null;

        return <>
            {
                menuDatas.map((item, key) =>
                    <NavLink
                        to={item.path}
                        activeClassName="active"
                        key={key}
                        onClick={() => {
                            // dispatch(getShop())
                            // let listShopID = [];
                            // if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
                            //     listShopID = [shopReducer.data.Shops[0].ID];
                            // } else if (userDataLocal.Role === CHAIN_MANAGER) {
                            //     if (!posReducer.shopFilter.listShopID) return;
                            //     listShopID = posReducer.shopFilter.listShopID
                            //         ? posReducer.shopFilter.listShopID
                            //         : shopReducer.data.Shops.map((item) => item.ID);
                            // }
                            // dispatch(
                            //     getFilterPOS(
                            //         pagingReducer.pos_grid.pageSize,
                            //         pagingReducer.pos_grid.pageNumber,
                            //         listShopID,
                            //         history
                            //     )
                            // );

                            item.callFunc()
                        }}
                    >
                        {item.title}
                    </NavLink>
                )
            }
        </>
    }

    // const _handleShowPopup = (show) => {
    //     const toogleBtn = toogleBtnRef.current;
    //     if (!toogleBtn) return;
    //     const styles = {}
    //     const dimensions = toogleBtn.getBoundingClientRect();

    //     styles.left = dimensions.left + (dimensions.width / 2) - 160 + 20;
    //     styles.left = Math.min(styles.left, document.body.clientWidth);
    //     styles.left += "px";

    //     styles.top = dimensions.top + dimensions.height + 30 + "px";
    //     const stylesToString = Object.keys(styles)
    //         .reduce((str, item) =>
    //             `${str}
    //             ${item}: ${styles[item]};
    //             `, ``)
    //     setStylesForLogoutPopup(stylesToString)

    //     show()
    // }

    const _renderUser = () => {
        if (authStatus === authStatusTypes.NOT_AUTH) {
            return null;
        }

        return <>
            <div className="block-info-user">

                <div className='shop-address'>
                    <p className='address' data-toggle="tooltip" title={
                        // userDataLocal.Role === STORE_MANAGER && shopReducer.data ?
                        [WUM, STORE_MANAGER].includes(userDataLocal.Role) && shopReducer.data ?
                            shopReducer.data.Shops[0].Address :
                            userDataLocal.Role === CHAIN_MANAGER && shopReducer.data ?
                                shopReducer.data.Chain.Address :
                                "Invalid Identity"
                    }>
                        {
                            // userDataLocal.Role === STORE_MANAGER && shopReducer.data && shopReducer.data.Shops[0].Address ?
                            [WUM, STORE_MANAGER].includes(userDataLocal.Role) && shopReducer.data && shopReducer.data.Shops[0].Address ?
                                shopReducer.data.Shops[0].Address :
                                userDataLocal.Role === CHAIN_MANAGER && shopReducer.data && shopReducer.data.Chain.Address ?
                                    shopReducer.data.Chain.Address :
                                    "-"
                        }
                    </p>
                    <IcLocation />
                </div>
                <div className='shop-phone'>
                    <p className='phone' data-toggle="tooltip" title={
                        // userDataLocal.Role === STORE_MANAGER && shopReducer.data && shopReducer.data.Shops[0].PhoneNumber ?
                        [WUM, STORE_MANAGER].includes(userDataLocal.Role) && shopReducer.data && shopReducer.data.Shops[0].PhoneNumber ?
                            shopReducer.data.Shops[0].PhoneNumber :
                            userDataLocal.Role === CHAIN_MANAGER && shopReducer.data && shopReducer.data.Chain.PhoneNumber ?
                                shopReducer.data.Chain.PhoneNumber :
                                "Invalid Identity"
                    }>
                        {
                            // userDataLocal.Role === STORE_MANAGER && shopReducer.data && shopReducer.data.Shops[0].PhoneNumber ?
                            [WUM, STORE_MANAGER].includes(userDataLocal.Role) && shopReducer.data && shopReducer.data.Shops[0].PhoneNumber ?
                                shopReducer.data.Shops[0].PhoneNumber :
                                userDataLocal.Role === CHAIN_MANAGER && shopReducer.data && shopReducer.data.Chain.PhoneNumber ?
                                    shopReducer.data.Chain.PhoneNumber :
                                    "-"
                        }
                    </p>
                    <IcPhoneMerchant />
                </div>
            </div>

            <div className="block-logout" onClick={() => dispatch(logout(history))}>
                <IcLogoutRed />
                <p>Đăng Xuất</p>
            </div>


            {/* <p>Family Mart</p>
            <img
                className="ic-user-login"
                src={avatar_demo}
                alt="avatar"
            />
            <ToggleContentControl
                toggle={show =>
                    <div
                        className="logout-block"
                        onClick={() => _handleShowPopup(show)}
                        ref={toogleBtnRef}
                    />
                }
                content={hide => (
                    <ModalControl>
                        <LogoutControl
                            logout={() => {
                                dispatch(logout(history))
                            }}
                            handleShowLogout={hide}
                            extendStyles={stylesForLogoutPopup}
                        />
                    </ModalControl>
                )}
            /> */}
        </>
    }

    return (
        <Bound ref={selfRef} id='header-id'>
            {/* Logo */}
            <img
                className="logo-facepay"
                src={logo_facepay}
                onClick={_handleClickLogo}
                alt="logo face pay"
                ref={logoRef}
            />

            {/* Menu */}
            <div className="menu">{_renderMenu()}</div>

            {/* User login */}
            <div className="user-login">
                {_renderUser()}
            </div>
        </Bound>
    );
}

export default Header;
