export const MATCH_ALL = "*";

export const keyMenu = {
    STATISTIC: 'STATISTIC',
    POS_DEVICE: 'POS_DEVICE',
}

const menuData = [
    { 
        title: "Thống kê", 
        path: "/main/statistic",
        permission: [MATCH_ALL],
        key: keyMenu.STATISTIC
    },
    { 
        title: "Thiết bị POS", 
        path: "/main/pos",
        permission: [MATCH_ALL],
        key: keyMenu.POS_DEVICE
    }
];

export default menuData