import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'


// styles
import { Bound, styleButton } from "./index.styles"

// images
import icon_warning from "./../../../../images/ic_warning.svg"

// constant
import { titleWarningChangeDevice } from '../../../../data/dataUI'
import { WUM, STORE_MANAGER, CHAIN_MANAGER } from '../../../../constant/key'

// component 
import ButtonControl from '../../../Control/ButtonControl'

// action
import { getFilterPOS } from '../../../../redux/actions/posActions'


const WarningChangeDevice = ({ closePopup, content }) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const { userReducer, shopReducer, pagingReducer, posReducer } = useSelector((state) => ({
        userReducer: state.userReducer,
        shopReducer: state.shopReducer,
        pagingReducer: state.pagingReducer,
        posReducer: state.posReducer,
    }))

    const [userDataLocal, setUserDataLocal] = useState({})

    useEffect(() => {
        if (!userReducer.loginResult) return;
        setUserDataLocal(userReducer.loginResult)
    }, [userReducer.loginResult])

    const _closeWarning = () => {
        let listShopID = [];
        if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
            listShopID = [shopReducer.data.Shops[0].ID];
        } else if (userDataLocal.Role === CHAIN_MANAGER) {
            listShopID = posReducer.shopFilter.listShopID
            ? posReducer.shopFilter.listShopID
            : shopReducer.data.Shops.map((item) => item.ID);
        }
        dispatch(getFilterPOS(
            pagingReducer.pos_list.pageSize,
            pagingReducer.pos_list.pageNumber,
            listShopID,
            history 
        ))

        closePopup();
    }

    return (
        <Bound>
            <div className="popup-container">
                <img src={icon_warning} alt="icon" />
                <p>{titleWarningChangeDevice}</p>
                <ButtonControl
                    extendStyles={styleButton}
                    handleClick={_closeWarning}
                >{content}</ButtonControl>
            </div>
        </Bound>
    )
}

WarningChangeDevice.propTypes = {
    closePopup: PropTypes.func.isRequired,
    content: PropTypes.any
}

export default WarningChangeDevice

