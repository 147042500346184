import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, useRouteMatch, Redirect, Link } from "react-router-dom";
import { useHistory, Route } from "react-router";

// router
import PrivateRoute from "../../../helper/PrivateRouter";

//image
import IcAddWhite from "../../../images/component/IcAddWhite";

//styles
import { Bound, styleButton } from "./index.styles";

import * as keys from "../../../constant/key";

// component
import POSPageAddNew from "./POSPageAddNew";
import ButtonControl from "../../Control/ButtonControl";
import POSPageGrid from "./POSPageGrid";
import POSPageList from "./POSPageList";

import IcGridOn from "../../../images/component/IcGridOn";
import IcGridOff from "../../../images/component/IcGridOff";
import IcListOn from "../../../images/component/IcListOn";
import IcListOfF from "../../../images/component/IcListOff";
import IcArrowRight from "../../../images/component/IcArrowRight";
import DropdownFilter from "../../Control/DropdownFilter";
import { getDropdownShopFilterPos } from "../../../redux/actions/posActions";
import { changePageNumber } from "../../../redux/actions/pagingAction";

const POSPage = () => {
    let displayConfig = JSON.parse(
        localStorage.getItem(keys.FACEPAY_DISPLAY_CONFIG)
    );
    let userDataLocal = JSON.parse(
        localStorage.getItem(keys.FACEPAY_USER_DATA)
    );

    const { path, url } = useRouteMatch();

    const [indexSelected, IsIndexSelected] = useState(1);

    const history = useHistory();
    const dispatch = useDispatch();

    const { shopReducer, pagingReducer } = useSelector(state => ({
        shopReducer: state.shopReducer,
        pagingReducer: state.pagingReducer
    }));

    const [dropdownFilterData, setDropdownFilterData] = useState([]);
    const [dropdownListSelect, setDropdownListSelect] = useState({});

    useEffect(() => {
        if (Object.keys(dropdownListSelect).length === 0) return;

        dispatch(getDropdownShopFilterPos(dropdownListSelect));
        if (displayConfig.POSMode === keys.POS_GRID)
            dispatch(changePageNumber(1, pagingReducer.pos_grid.type));
        else if (displayConfig.POSMode === keys.POS_LIST)
            dispatch(changePageNumber(1, pagingReducer.pos_list.type));
    }, [dropdownListSelect]);

    useEffect(() => {
        if (!shopReducer.stores) return;
        const arr = [];
        shopReducer.stores.forEach(shop => {
            arr.push({
                id: shop.id,
                name: shop.AreaName,
                children: shop.Shops.slice().map(merchance => ({
                    id: merchance.ID,
                    name: merchance.Name,
                    address: merchance.Address
                }))
            });
            return shop;
        });
        setDropdownFilterData(arr);
    }, [shopReducer.stores]);

    useEffect(() => {
        setDisplayMode();
    }, []);

    const setDisplayMode = () => {
        if (displayConfig) {
            switch (displayConfig.POSMode) {
                case keys.POS_GRID:
                    IsIndexSelected(1);
                    break;
                case keys.POS_LIST:
                    IsIndexSelected(-1);
                    break;
            }
        } else {
            localStorage.setItem(
                keys.FACEPAY_DISPLAY_CONFIG,
                JSON.stringify({ POSMode: keys.POS_GRID })
            );
        }
    };

    const toggleSelect = type => {
        if (type === "pos-grid") {
            IsIndexSelected(1);
            localStorage.setItem(
                keys.FACEPAY_DISPLAY_CONFIG,
                JSON.stringify({ ...displayConfig, POSMode: keys.POS_GRID })
            );
        }
        if (type === "pos-list") {
            IsIndexSelected(-1);
            localStorage.setItem(
                keys.FACEPAY_DISPLAY_CONFIG,
                JSON.stringify({ ...displayConfig, POSMode: keys.POS_LIST })
            );
        }
    };

    const _roleBackPosPage = () => {
        if (userDataLocal.LinkPos) history.push("/main/pos");
    };

    return (
        <Bound>
            <div className="head-container">
                {window.location.pathname === "/main/pos/add" ? (
                    <div className="headTitle_addnew">
                        <p onClick={_roleBackPosPage}>Danh sách thiết bị</p>
                        <IcArrowRight className="ic-arrow" />
                        <p> Thêm thiết bị</p>
                    </div>
                ) : (
                    <p className="head-title">Danh sách thiết bị</p>
                )}
                <Route exact path={`${path}`}>
                    <div className="button-control">
                        {userDataLocal &&
                        // userDataLocal.Role === keys.STORE_MANAGER ? (
                        [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) ? (
                            <Link to={`${url}/add`}>
                                <ButtonControl extendStyles={styleButton}>
                                    <IcAddWhite className="ic-white" />
                                    THÊM THIẾT BỊ
                                </ButtonControl>
                            </Link>
                        ) : (
                            <DropdownFilter
                                inputPlaceHolder="Tìm kiếm tên cửa hàng"
                                preText={"Cửa hàng đang xem"}
                                placeholder={"Chọn cửa hàng"}
                                dataDropdown={dropdownFilterData}
                                dispatchListSelect={setDropdownListSelect}
                            />
                        )}
                        <div
                            className="icon-grid"
                            onClick={() => {
                                toggleSelect("pos-grid");
                            }}
                        >
                            {indexSelected === 1 ? (
                                <div data-toggle="tooltip" title="Xem dạng lưới">
                                    <IcGridOn />
                                </div>
                            ) : (
                                <div data-toggle="tooltip" title="Xem dạng lưới">
                                    <IcGridOff />
                                </div>
                            )}
                        </div>
                        <div
                            className="icon-list"
                            onClick={() => {
                                toggleSelect("pos-list");
                            }}
                        >
                            {indexSelected === -1 ? (
                                <div data-toggle="tooltip" title="Xem dạng bảng">
                                    <IcListOn />
                                </div>
                            ) : (
                                <div data-toggle="tooltip" title="Xem dạng bảng">
                                    <IcListOfF />
                                </div>
                            )}
                        </div>
                    </div>
                </Route>
            </div>
            <Switch>
                <Route exact path={`${path}`}>
                    {indexSelected === 1 ? <POSPageGrid /> : <POSPageList />}
                </Route>
                {/* {userDataLocal && userDataLocal.Role === keys.STORE_MANAGER ? ( */}
                {userDataLocal && [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) ? (
                    <Route path={`${path}/add`}>
                        <POSPageAddNew />
                    </Route>
                ) : null}
                <Redirect to={`${path}`} />
            </Switch>
        </Bound>
    );
};

export default POSPage;
