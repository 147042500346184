// export const host = 'http://192.168.1.217:7088/v1/'
//Local
// export const host           = 'http://weezi.biz:7088/v1/' 
//Dev
// export const host           = 'https://mpos-dev.facepay.vn/v1/'
// export const host           = 'https://dev.facepay.vn/v1/'

//QC
// export const host           = 'https://mpos-qc.facepay.vn/v1/'

//PILLOT
// export const host           = 'https://mpos-beta.facepay.vn/v1/'

//CMC
export const host           = 'https://pos.facepay.vn/v1/'
